import {DefaultService} from "@/services/default";


export class OperationService extends DefaultService {
    static resourceURL = 'operation/'

    static listBooking(params = {}, headers = {}) {
        return this.request('list-bookings/', 'GET', null, params, headers);
    }

    static listSale(params = {}, headers = {}) {
        return this.request('list-sales/', 'GET', null, params, headers);
    }
}