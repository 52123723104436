const OperationBookingList = () => import("@/views/operation/OperationBookingList.vue");
const OperationBooking = () => import("@/views/operation/OperationBooking.vue");
const OperationSale = () => import("@/views/operation/OperationSale.vue");

const operation_routes = [
    {
        path: '',
        name: 'OperationBookingList',
        component: OperationBookingList,
        props: route => ({ params: route.query })
    },
    {
        path: '20',
        name: 'OperationSale',
        component: OperationSale,
        props: route => ({params: route.query, pk: route.params.pk})
    },
    {
        path: ':pk',
        name: 'OperationBooking',
        component: OperationBooking,
        props: route => ({ params: route.query, pk: route.params.pk })
    }
]
export default operation_routes;