import {createRouter, createWebHistory} from 'vue-router'
import product_routes from "@/router/product";
import misc_routes from "@/router/misc";
import order_routes from "@/router/order";
import customer_routes from "@/router/customer";
import operation_routes from "@/router/operation";
import {useAppStore, useAuthStore} from "@/stores";
import {ref} from "vue";

const Home = () => import("@/views/Home.vue");
const Calendar = () => import("@/views/calendar/Calendar.vue");
const Login = () => import("@/views/auth/Login.vue");
const Register = () => import("@/views/auth/Register.vue");

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        { path: '/', redirect: '/home' },
        { path: '/home', name: 'Home', component: Home },
        { path: '/login', name: 'Login', component: Login },
        { path: '/register', name: 'Register', component: Register },
        { path: '/product', children: product_routes },
        { path: '/misc', children: misc_routes },
        { path: '/order', children: order_routes },
        { path: '/customer', children: customer_routes },
        { path: '/operation', children: operation_routes },
        { path: '/calendar', name: 'Calendar', component: Calendar, props: { title: 'Calendar' } },
        { path: "/:catchAll(.*)", redirect: '/' },
    ]
})
router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/register'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();
    const appStore = ref(useAppStore());
    const loggedIn = auth.user;


    if (authRequired && loggedIn) {
        auth.returnUrl = to.fullPath;
        try {
            await auth.verify();
        } catch (e) {
            // appStore.value.addToast('Error', 'Session Expired', 'danger')
            await auth.logout();
            return '/login';
        }
    }
    if (authRequired && !loggedIn) {
        auth.returnUrl = to.fullPath;
        return '/login';
    }
    if (!authRequired && loggedIn) {
        return '/';
    }
    return true;
});
// router.afterEach((to, from) => {
//     document.querySelectorAll('.tooltip').forEach((e) => e.parentElement.removeChild(e))
// });
export default router