import axios from "axios";
import {useAuthStore} from "@/stores";

export class DefaultService {
    static resourceURL = '';
    static apiAPP = 'api';
    static authAPP = 'users';

    static get serverURL() {
        return import.meta.env.VITE_BACKEND_URL || "http://127.0.0.1:8080";
    }

    static appURL(app) {
        if (!app) {
            return `${this.serverURL}/api`;
        } else {
            return `${this.serverURL}/${app}`;
        }
    }

    static list(params = {}, headers = {}) {
        return this.request('', 'GET', null, params, headers);
    }

    static retrieve(id, params = {}, headers = {}) {
        return this.request(`${id}/`, 'GET', null, params, headers);
    }

    static create(data, params = {}, headers = {}) {
        return this.request('', 'POST', data, params, headers);
    }

    static update(id, data, params = {}, headers = {}) {
        return this.request(`${id}/`, 'PUT', data, params, headers);
    }

    static patch(id, data, params = {}, headers = {}) {
        return this.request(`${id}/`, 'PATCH', data, params, headers);
    }

    static delete(id, params = {}, headers = {}) {
        return this.request(`${id}/`, 'DELETE', null, params, headers);
    }

    static find(params = {}, headers = {}) {
        return this.request('find/', 'GET', null, params, headers);

    }

    static archive(id, params = {}, headers = {}) {
        return this.request(`${id}/archive/`, 'DELETE', null, params, headers);
    }

    static restore(id, params = {}, headers = {}) {
        return this.request(`${id}/restore/`, 'GET', null, params, headers);
    }

    static batchDelete(ids, params = {}, headers = {}) {
        return this.request('batch-delete/', 'DELETE', ids, params, headers);
    }

    static batchArchive(ids, params = {}, headers = {}) {
        return this.request('batch-archive/', 'DELETE', ids, params, headers);
    }

    static batchRestore(ids, params = {}, headers = {}) {
        return this.request('batch-restore/', 'DELETE', ids, params, headers);
    }

    static export(params = {}, headers = {}) {
        return this.request('export/', 'GET', null, params, headers, 'blob');
    }

    static async request(url, method, data = null, params = null, headers = null, responseType = null, app) {
        const fullUrl = `${this.appURL(app)}/${this.resourceURL}${url}`;
        const request = {
            method: method,
            url: fullUrl,
            data: data,
            headers: this.authHeader(fullUrl, headers),
            params: params,
            paramsSerializer: {
                indexes: null, // prevent axios from adding indexes to array params
            },
            responseType: responseType
        };

        try {
            return await axios(request);
        } catch (error) {
            return await this.handleError(error);
        }
    }

    static authHeader(url, headers) {
        // return auth header with jwt if user is logged in and request is to the api url
        const {access} = useAuthStore();
        const isLoggedIn = !!access;
        const isApiUrl = url.startsWith(`${this.serverURL}/${this.apiAPP}`);
        const isProfile = url.startsWith(`${this.authAPP}/profile`);
        if ((isLoggedIn && isApiUrl) || isProfile) {
            headers = {...headers, Authorization: `Bearer ${access}`};
        }
        return headers;
    }

    static async handleError(error) {
        if (error.response && error.response.status === 401) {
            console.log("Unauthorized");
            await useAuthStore().logout().then()
            throw "Session Expired";
        } else if (error.response && error.response.status !== 500) {
            let message = ""
            if (typeof error.response.data === 'object') {
                message = JSON.stringify(error.response.data)
            } else {
                message = error.response.data
            }
            console.log(message);
            throw message;
        } else {
            console.log(error);
            throw error.message;
        }
    }
}